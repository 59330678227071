body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
  z-index: 1401;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 1401;
}

.paginations---dropdown-menu---1fkH0 {
  top: -138px !important;
}